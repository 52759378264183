import {
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';

import { sortAlphabetically } from '../../utils/utils';
import DownIcon from '../../icons/DownIcon';
import CustomCheckIcon from '../../icons/CustomCheckIcon';

function MultiSelectDropdown({
  options, onChange, selectedValues, getOptionLabel, label,
  maxWidth = '',
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showGradient, setShowGradient] = useState(false);
  const [dynamicMatchWidth, setDynamicMatchWidth] = useState(false);
  const menuListRef = useRef(null);
  const menuButtonRef = useRef(null);

  const handleSelect = (value) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((item) => item !== value)
      : [...selectedValues, value];

    onChange(newSelectedValues);
  };

  const getButtonLabel = () => ((Array.isArray(selectedValues)
    && selectedValues?.map((es) => getOptionLabel(es))
      .filter((es) => es)
      .join(', ')) || label);

  const handleScroll = () => {
    if (menuListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuListRef.current;
      setShowGradient(scrollHeight > clientHeight && scrollTop + clientHeight < scrollHeight);
    }
    return true;
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const currentMenuList = menuListRef.current;

    if (currentMenuList) {
      handleScroll(); // Check initial scroll position
      currentMenuList.addEventListener('scroll', handleScroll);
      return () => {
        currentMenuList.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (menuButtonRef.current && menuListRef.current) {
      const buttonWidth = menuButtonRef.current.offsetWidth;
      const itemWidths = Array.from(menuListRef.current.children).map((child) => child.offsetWidth);
      const maxItemWidth = Math.max(...itemWidths);

      if (maxItemWidth < buttonWidth) {
        setDynamicMatchWidth(true);
      } else {
        setDynamicMatchWidth(false);
      }
    }
  }, [options]);

  return (
    <Box width={maxWidth || '250px'}>
      <Menu
        isOpen={isOpen}
        onClose={onClose}
        gutter={0}
        closeOnSelect={false}
        matchWidth={dynamicMatchWidth}
      >
        <MenuButton
          ref={menuButtonRef}
          as={Button}
          onClick={onOpen}
          variant="multiselect"
          rightIcon={<DownIcon className={`${isOpen ? 'rotateUp' : 'rotateDown'}`} />}
          width="100%"
          borderColor="gray.900"
        >
          {getButtonLabel()}
        </MenuButton>
        <Box position="relative">
          <MenuList
            ref={menuListRef}
            maxHeight="250px"
            overflowY="auto"
            paddingBottom={4}
          >
            {options
              .sort(sortAlphabetically)
              .map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => handleSelect(option)}
                  display="flex"
                  alignItems="center"
                >
                  <Box onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      icon={<CustomCheckIcon />}
                      isChecked={selectedValues?.includes(option)}
                      onChange={() => handleSelect(option)}
                      mr={2}
                    />
                  </Box>
                  {getOptionLabel(option)}
                </MenuItem>
              ))}
            {showGradient && (
              <Box
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                height="30px"
                bgGradient="linear(to-t, white, rgba(255, 255, 255, 0.3))"
                pointerEvents="none"
              />
            )}
          </MenuList>
        </Box>
      </Menu>
    </Box>
  );
}

MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
};

export default MultiSelectDropdown;
