import { useTranslation } from 'react-i18next';
import { HStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useCatalogueFilters } from '../../api/hooks';
import Alert from '../ui/Alert';
import MultiSelectDropdown from '../ui/MultiSelectDropdown';

function Filters(props) {
  const {
    setStandards,
    setLocales,
    setStatuses,
    filters,
  } = props;
  const {
    standards,
    locales,
    statuses,
  } = filters;
  const { t } = useTranslation();

  const { data, error, isError } = useCatalogueFilters();

  if (isError) {
    return (
      <Alert variant="error">
        {error?.message || JSON.stringify(error)}
      </Alert>
    );
  }

  return (
    <HStack marginBottom="40px" justifyContent="flex-start">
      {data && (
        <>
          <MultiSelectDropdown
            onChange={setStandards}
            options={data?.standards}
            selectedValues={standards}
            getOptionLabel={(o) => t(`transformations.${o}.pretty_name`)}
            label={t('exchangeStandard')}
            closeOnSelect={false}
          />

          <MultiSelectDropdown
            onChange={setLocales}
            options={data?.locales}
            selectedValues={locales}
            getOptionLabel={(o) => t(`locales.${o}`)}
            label={t('locale.heading')}
            closeOnSelect={false}
          />

          {
            (data?.statuses?.length > 1) && (
              <MultiSelectDropdown
                onChange={setStatuses}
                options={data.statuses}
                selectedValues={statuses}
                getOptionLabel={(o) => t(`statuses.options.${o}`)}
                label={t('statuses.heading')}
                closeOnSelect={false}
              />
            )
          }
        </>
      )}
    </HStack>
  );
}

Filters.propTypes = {
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  setStandards: PropTypes.func.isRequired,
  setLocales: PropTypes.func.isRequired,
  setStatuses: PropTypes.func.isRequired,
};

export default Filters;
