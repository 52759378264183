import { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import i18next from 'i18next';

import ToggleSidebarButton from './ToggleSidebarButton';
import {
  getTestID,
  isMatch,
} from '../../utils/utils';
import { languageLabel, routes } from '../../utils/constants';
import SignOutButton from './SignOutButton';
import CategoriesIcon from '../../icons/CategoriesIcon';
import DatapointsIcon from '../../icons/DatapointsIcon';
import SettingsIcon from '../../icons/SettingsIcons';
import ProductImagesIcon from '../../icons/ProductImagesIcon';
import UnknownProductIcon from '../../icons/UnknownProductIcon';
import PlusIcon from '../../icons/PlusIcon';

const isCatalogues = (path) => {
  if (path === routes.ROOT) {
    return true;
  }
  return isMatch([routes.CATALOGUE, routes.REVISION, routes.CREATE_REVISION], path);
};
const isSystemStatus = (path) => isMatch([routes.SYSTEM_STATUS], path);
const isTransformations = (path) => isMatch(
  [routes.TRANSFORMATIONS, routes.CLONE_CATALOGUE],
  path,
);
const isSettings = (path) => isMatch([routes.SETTINGS], path);
const isDexAssetsPortal = (path) => isMatch([routes.DEX_ASSETS_PORTAL], path);
const isAvailability = (path) => isMatch([routes.ARTICLE_AVAILABILITY], path);

function SidebarNavigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  return (
    <>
      <Flex
        position="fixed"
        flexDirection="column"
        width={isExpanded ? '280px' : '60px'}
        minHeight="100vh"
        backgroundColor="gray.50"
        zIndex={999}
        transition="width 0.3s"
        transitionTimingFunction="cubic-bezier (0, 0, 0.25, 1)"
      >
        <ToggleSidebarButton
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        <Button
          data-testid={getTestID('SidebarButtonRoot')}
          leftIcon={<CategoriesIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isCatalogues(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            navigate(routes.ROOT);
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.catalogues')}</span>
        </Button>
        <Button
          data-testid={getTestID('SidebarButtonSystemStatus')}
          leftIcon={<DatapointsIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isSystemStatus(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            navigate(routes.SYSTEM_STATUS);
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.systemStatus')}</span>
        </Button>
        <Button
          data-testid={getTestID('SidebarButtonSettings')}
          leftIcon={<SettingsIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isSettings(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            navigate(routes.SETTINGS);
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.settings')}</span>
        </Button>
        <Button
          data-testid={getTestID('SidebarButtonAssetsPortal')}
          leftIcon={<ProductImagesIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isDexAssetsPortal(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            navigate(routes.DEX_ASSETS_PORTAL);
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.dexAssetsPortal')}</span>
        </Button>
        <Button
          data-testid={getTestID('SidebarButtonArticleAvailability')}
          leftIcon={<UnknownProductIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isAvailability(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            navigate(routes.ARTICLE_AVAILABILITY);
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.availabilityCheck')}</span>
        </Button>
        <Divider />
        <Button
          data-testid={getTestID('SidebarButtonTransformations')}
          leftIcon={<PlusIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isTransformations(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            /* Force a full page reload using window.location.href instead of navigate.
              This will bypass React Router’s built-in behavior that prevents reloading
              when navigating to the same route. */
            window.location.href = routes.TRANSFORMATIONS;
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.newCatalogue')}</span>
        </Button>
        <Flex
          w="100%"
          flexDir="column"
          marginTop="auto"
        >
          <Menu gutter={0}>
            <MenuButton
              as={Button}
              variant="sidebarItem"
              data-testid={getTestID('LanguageSwitch')}

            >
              {languageLabel[i18next.language] || languageLabel[i18next.resolvedLanguage]}
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                type="radio"
                defaultValue={i18next.resolvedLanguage}
                onChange={(key) => changeLanguage(key)}
              >
                <MenuItemOption
                  key="de-DE"
                  value="de-DE"
                  data-testid={getTestID('LanguageSwitchToDe')}
                >
                  DE - Deutsch
                </MenuItemOption>
                <MenuItemOption
                  key="en-GB"
                  value="en-GB"
                  data-testid={getTestID('LanguageSwitchToEn')}
                >
                  EN - English
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>

          <SignOutButton />
        </Flex>
      </Flex>
      <Box
        id="overlay"
        position="fixed"
        display={isExpanded ? 'block' : 'none'}
        w="100%"
        h="100%"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="rgba(0,0,0,0.2)"
        zIndex="998"
        onClick={() => setIsExpanded(false)}
      />
    </>
  );
}

export default SidebarNavigation;
