import {
  Container,
  Image,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import UserLoggedIn from './UserLoggedIn';
import useUserStore from '../../services/UserStore';
import logo from '../../assets/img/geberit-logo.png';
import LimitedNav from './LimitedNav';

function LimitedLayout(props) {
  const { children } = props;
  const user = useUserStore((s) => s.user);

  return (
    <Grid
      templateAreas={`"nav header" 
                      "nav main"`}
      gridTemplateRows="120px 1fr"
      gridTemplateColumns="80px 1fr"
    >
      <GridItem area="nav">
        <LimitedNav />
      </GridItem>
      <GridItem area="header">
        <Container variant="header">
          <Flex justifyContent="space-between" alignContent="center">
            <Image src={logo} alt="Geberit" width="148px" />
            {!!user?.fullName && <UserLoggedIn fullName={user.fullName} />}
          </Flex>
        </Container>
      </GridItem>
      <GridItem area="main">
        <Container variant="main">
          {children}
        </Container>
      </GridItem>
    </Grid>
  );
}

LimitedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LimitedLayout;
