import { Image } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';

function BackgroundImage({ src }) {
  return (
    <Image
      data-testid={getTestID('BackgroundImage')}
      position="absolute"
      top={0}
      left={0}
      zIndex="-1000"
      minWidth="100%"
      minHeight="100%"
      objectFit="cover"
      src={src}
    />
  );
}

BackgroundImage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default BackgroundImage;
