import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  formatDate,
  formatParametersPresetsData,
  getTestID,
} from '../../utils/utils';
import { routes } from '../../utils/constants';
import SecondsAsEstimatedTime from '../common/SecondsAsEstimatedTime';
import Back from '../ui/Back';
import Error from '../ui/Error';
import GeberitSpinner from '../ui/GeberitSpinner';
import Alert from '../ui/Alert';
import Tag from '../ui/Tag';
import Collaborators from './Collaborators';
import EllipsisMenu from './EllipsisMenu';
import RevisionTable from '../CatalogueList/RevisionTable/RevisionTable';
import {
  useCatalogue, useConfiguration, useRequestCheck, useTransformation,
} from '../../api/hooks';
import KeyValuePairs from '../ui/KeyValuePairs';

function Catalogue() {
  const [presetsVisible, setPresetsVisible] = useState(false);
  const [parametersVisible, setParametersVisible] = useState(true);

  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: catalogue, loading, error } = useCatalogue(uuid);

  /* Checks if a revision can be created for a catalogue
    or if the transformation is deprecated */
  const { data: requestCheck } = useRequestCheck(uuid);

  const isCatalogueArchived = catalogue?.statuses?.includes('archived');
  const isTransformationDeprecated = !isCatalogueArchived
    && requestCheck?.isExchangeStandardDeprecated;
  const isRevisionForbidden = !isCatalogueArchived
    && (!requestCheck?.isExchangeStandardDeprecated && !requestCheck?.canCreateRevisionFlag);

  const {
    data: transformation,
  } = useTransformation(
    catalogue?.exchangeStandard,
    !!(requestCheck && !requestCheck.isExchangeStandardDeprecated),
  );

  /* Takes the sanitized data for deprecated catalogues */
  const { data: configuration } = useConfiguration(uuid, requestCheck);

  const [mergedCatalogues, setMergedCatalogues] = useState({});

  useEffect(() => {
    setMergedCatalogues({
      ...catalogue,
      parameters: configuration?.parameters,
      presets: configuration?.presets,
    });
  }, [configuration, catalogue]);

  const parameterKeyValues = catalogue?.parameters?.map((param) => (
    {
      key: t(`parameters.${param.name}.label`),
      value: formatParametersPresetsData(param, t('notSpecified')),
    }
  ));

  const presetKeyValues = catalogue?.presets?.map((preset) => (
    {
      key: t(`presets.${catalogue.exchangeStandard}.${preset.name}.label`),
      value: formatParametersPresetsData(preset, t('notSpecified')),
    }
  ));

  const catalogueInfoValuePairs = [
    {
      key: t('catalogueOwner'),
      value: catalogue?.requestedBy?.fullName,
    },
    {
      key: t('createdOn'),
      value: formatDate(catalogue?.createdOn),
    },
  ];

  return (
    <Box data-testid={getTestID('Catalogue')}>
      <Back to="/" text={t('backToOverview')} />
      {loading && <GeberitSpinner />}
      {catalogue && (
        <>
          <Flex justifyContent="space-between">
            <Heading as="h1" size="h1">
              {catalogue?.name}
            </Heading>
            <Flex>
              <EllipsisMenu
                catalogue={mergedCatalogues || {}}
                transformation={transformation || {}}
              />
            </Flex>
          </Flex>
          <SecondsAsEstimatedTime
            totalSeconds={catalogue?.averageTimeTakenSec}
          />
          <Box flex={{ base: '0 0 100%', md: '0 0 60%' }}>
            <KeyValuePairs list={catalogueInfoValuePairs} templateColumns={{ base: '1fr' }} gridGap="4" />
          </Box>
          <Text data-testid={getTestID('DescriptionText')} marginBottom="8">
            {catalogue?.description}
          </Text>
          {isCatalogueArchived && (
            <Alert variant="warning">
              {`${t('catalogue.isArchived')} `}
            </Alert>
          )}
          {requestCheck && isTransformationDeprecated && (
            <Alert variant="warning">
              <>
                {`${t('catalogue.exchangeStandardNotSupported')} `}
                <Link href={routes.TRANSFORMATIONS}>{t('catalogue.pleaseCreateNew')}</Link>
              </>
            </Alert>
          )}
          {requestCheck && isRevisionForbidden && (
            <Alert variant="warning">
              <>
                {`${t('catalogue.cannotCreateRevision')} `}
                <Button
                  variant="inlineLink"
                  href={routes.CLONE_CATALOGUE}
                  onClick={() => navigate(
                    routes.CLONE_CATALOGUE,
                    { state: { catalogue: mergedCatalogues, transformation } },
                  )}
                >
                  {t('catalogue.pleaseDuplicate')}
                </Button>
              </>
            </Alert>
          )}
          <Divider />
          {catalogue?.parameters?.length > 0 && (
            <>
              <Flex justifyContent="space-between" marginBottom="15px">
                <Flex gap="2" alignItems="center">
                  <Heading margin={0} alignSelf="center">
                    {t('inputParameters')}
                    &nbsp;
                    <Text as="span" fontWeight="normal">
                      {t(
                        `transformations.${catalogue?.exchangeStandard}.pretty_name`,
                      )}
                    </Text>
                  </Heading>
                  {isTransformationDeprecated
                    && (
                      <Tag>
                        {t('catalogue.deprecated')}
                      </Tag>
                    )}
                  {isCatalogueArchived && (
                    <Tag>
                      {t('archived')}
                    </Tag>
                  )}
                </Flex>
                <Button
                  variant="link"
                  alignSelf="center"
                  onClick={() => setParametersVisible(!parametersVisible)}
                >
                  {parametersVisible ? t('hide') : t('show')}
                </Button>
              </Flex>
              <Collapse in={parametersVisible}>
                <KeyValuePairs list={parameterKeyValues} />
              </Collapse>
              <Divider />
            </>
          )}
          {catalogue?.presets?.length > 0 && (
            <>
              <Flex justifyContent="space-between">
                <Heading margin={0} alignSelf="center">
                  {t('transformationPresets')}
                </Heading>
                <Button
                  variant="link"
                  alignSelf="center"
                  onClick={() => setPresetsVisible(!presetsVisible)}
                >
                  {presetsVisible ? t('hide') : t('show')}
                </Button>
              </Flex>
              <Collapse in={presetsVisible}>
                <KeyValuePairs list={presetKeyValues} />
              </Collapse>
              <Divider />
            </>
          )}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            fontWeight="700"
            marginBottom="8"
          >
            <Heading margin={0}>
              {t('revisions.name')}
              &nbsp;
              {catalogue?.revisions && (
                <Text as="span">
                  {`(${catalogue?.revisions.length})`}
                </Text>
              )}
            </Heading>
            <Button
              variant="primary"
              onClick={() => navigate(`/create-revision?parentUuid=${uuid}`)}
              isDisabled={isCatalogueArchived
                || (isRevisionForbidden || isTransformationDeprecated)}
            >
              <AddIcon marginRight="1" />
              <Text as="span">{t('revision.create')}</Text>
            </Button>
          </Flex>
          {catalogue?.revisions?.length > 0 && (
            <RevisionTable
              catalogue={catalogue}
              revisions={catalogue?.revisions}
            />
          )}
          <Divider />
          <Collaborators catalogue={catalogue} />
        </>
      )}
      {!catalogue && !loading && !uuid && (
        <>
          <Heading as="h1" size="h1">
            {t('catalogue.notFound')}
          </Heading>
          <Text>{t('catalogue.notFoundBlurb')}</Text>
        </>
      )}
      {error && <Error error={error} />}
    </Box>
  );
}

export default Catalogue;
