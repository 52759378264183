import { useBreakpointValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import CatalogueTableSmall from './CatalogueTableSmall';
import CatalogueTableLarge from './CatalogueTableLarge';
import { useRowSelection } from '../hooks';

function CatalogueTable({ data }) {
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });
  const [selectedIndexes, toggleRow] = useRowSelection();

  return isLargeScreen
    ? <CatalogueTableLarge data={data} selectedIndexes={selectedIndexes} toggleRow={toggleRow} />
    : <CatalogueTableSmall data={data} selectedIndexes={selectedIndexes} toggleRow={toggleRow} />;
}

CatalogueTable.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({
      catalogues: PropTypes.arrayOf(PropTypes.shape({
        catalogueUuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })).isRequired,
    })).isRequired,
  }).isRequired,
};

export default CatalogueTable;
