import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getTestID } from '../../utils/utils';
import DownIcon from '../../icons/DownIcon';
import CustomCheckIcon from '../../icons/CustomCheckIcon';
import SearchIcon from '../../icons/SearchIcon';

function SingleSelectDropdown({
  name,
  onChange,
  label,
  children,
  matchWidth = true,
  maxWidth = '30%',
  onBlur = () => { },
  disabled = false,
  value = undefined,
  searchbox = false,
  autoFocus = false,
}) {
  const { t } = useTranslation();
  const [showGradient, setShowGradient] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const menuListRef = useRef(null);
  const searchInputRef = useRef(null);
  const { isOpen: open } = useDisclosure();

  const getOptionLabel = (v) => {
    if (v == null) return label; // Handle null or undefined values
    const selectedChild = React.Children.toArray(children)
      .find((child) => child.props.value === v);
    return selectedChild ? selectedChild.props.children : v;
  };

  // eslint-disable-next-line consistent-return
  const handleScroll = () => {
    if (menuListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuListRef.current;
      setShowGradient(scrollHeight > clientHeight && scrollTop + clientHeight < scrollHeight);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const currentMenuList = menuListRef.current;

    if (currentMenuList) {
      handleScroll(); // Check initial scroll position
      currentMenuList.addEventListener('scroll', handleScroll);
      return () => {
        currentMenuList.removeEventListener('scroll', handleScroll);
      };
    }
  }, [open]);

  const options = searchbox ? React.Children.toArray(children)?.filter(
    (child) => child.props.children.toLowerCase().includes(searchQuery.toLowerCase()),
  ) : React.Children.toArray(children);

  return (
    <Box width={maxWidth || '250px'}>
      <Menu
        gutter={0}
        autoSelect={false}
        closeOnSelect
        matchWidth={matchWidth}
      >
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              autoFocus={autoFocus}
              data-testid={getTestID('StandardAndCountryListDropDown')}
              maxW={maxWidth}
              as={Button}
              variant="multiselect"
              rightIcon={<DownIcon className={`${isOpen ? 'rotateUp' : 'rotateDown'}`} />}
              name={name}
              onBlur={onBlur}
              disabled={disabled}
              borderColor="gray.900"
            >
              {getOptionLabel(value) || label}
            </MenuButton>
            <MenuList
              maxH="200px"
              overflowY="auto"
              paddingBottom={4}
              paddingTop={0}
              onMouseLeave={onClose}
              onFocus={() => {
                // Handle focus override
                if (searchbox && searchInputRef.current) {
                  setTimeout(() => {
                    searchInputRef.current?.focus();
                  }, 0);
                }
              }}
              ref={menuListRef}
            >
              {searchbox && (
                <Box padding="8px" position="sticky" top="0" bg="white" zIndex="1">
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      ref={searchInputRef}
                      placeholder={t('search')}
                      defaultValue={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      autoFocus
                      mb={2}
                      required={false}
                    />
                  </InputGroup>
                </Box>
              )}
              <MenuOptionGroup
                type="radio"
                value={value}
                onChange={(v) => {
                  onChange({ target: { name, value: v } });
                }}
              >
                {options.map((child) => (
                  <MenuItemOption
                    data-testid={getTestID('DropDownElement')}
                    type="radio"
                    key={child.props.value}
                    value={child.props.value}
                    fontWeight="normal"
                    icon={<CustomCheckIcon />}
                    isDisabled={child.props.disabled}
                  >
                    {child.props.children}
                  </MenuItemOption>
                ))}
                {showGradient && (
                  <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    height="30px"
                    bgGradient="linear(to-t, white, rgba(255, 255, 255, 0.3))"
                    pointerEvents="none"
                  />
                )}
              </MenuOptionGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
}

SingleSelectDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  matchWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  searchbox: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default SingleSelectDropdown;
