import { useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useCreateCatalogue,
  useTransformation,
  useTransformations,
} from '../../api/hooks';
import FormStepper from './Form/FormStepper';
import Transformations from './FormSteps/Transformations';
import Parameters from './FormSteps/Parameters';
import Assortment from './FormSteps/Assortment';
import {
  generateCatalogue,
  generateInitialFormValues,
} from './utils';
import { generateValidationSchemaForParametersAndPresets } from './validationSchemaUtils';
import validationSchema from './validationSchema';

function CatalogueCreate() {
  const [selectedTransformation, setSelectedTransformation] = useState();
  const navigate = useNavigate();
  const [selectedCountry] = useLocalStorage('countryLanguage');
  const { t } = useTranslation();

  const { data: transformations, isLoading: transformationsLoading } = useTransformations();

  const {
    data: transformation,
    isLoading: transformationLoading,
  } = useTransformation(selectedTransformation);

  const { mutate: createCatalogue } = useCreateCatalogue();

  const handleCreate = (catalogue) => {
    createCatalogue({ catalogue }, {
      onSuccess: ({ data }) => {
        navigate(`/catalogue/${data.uuid}`);
      },
    });
  };

  // Memoize the validation schema calculation
  const parametersAndPresetsSchema = useMemo(
    () => generateValidationSchemaForParametersAndPresets(transformation, t),
    [transformation, t],
  );

  return (
    <FormStepper
      initialValues={
        generateInitialFormValues(selectedTransformation, transformation, selectedCountry)
      }
      validationSchema={[...validationSchema, parametersAndPresetsSchema]}
      onSubmit={(formValues) => {
        const catalogue = generateCatalogue(formValues, transformation);
        handleCreate(catalogue);
        return catalogue;
      }}
    >
      <Transformations
        transformations={transformations}
        setSelectedTransformation={setSelectedTransformation}
        transformationsLoading={transformationsLoading}
      />
      <Assortment
        transformationData={transformation}
        transformationDataLoading={transformationLoading}
      />
      {transformation?.presets.length > 0 ? (
        <Parameters
          transformationData={transformation}
          transformationDataLoading={transformationLoading}
        />
      ) : null}
    </FormStepper>
  );
}

export default CatalogueCreate;
