import { inputTypes } from '../../utils/constants';
import parseArticleKeys from './parseArticleKeys';

const initialValues = {
  name: '',
  description: '',
  articleList: '',
};

// eslint-disable-next-line max-len
export const generateInitialFormValues = (selectedTransformation, transformation, selectedCountry) => {
  /* Map over presets and transform them into an object where each preset's name is a key
    If the preset style is MULTISELECT, split the default value by commas or use an empty array
    Otherwise, use the default value or an empty string */
  const presets = transformation?.presets
    .map((p) => {
      if (p.style === inputTypes.MULTISELECT) {
        return { [p.name]: p.default?.split(',') || [''] };
      }
      return { [p.name]: p.default || '' };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  /* Map over parameters and transform them into an object where each parameter's name is a key
    Handle special cases for the 'locale' parameter and the MULTISELECT and DATE types
    If the parameter is 'locale' and includes the selected country,
    use the selected country or the default value
    If the parameter style is MULTISELECT, split the default value by commas or use an empty array
    If the parameter type is DATE, use the default value or undefined
    Otherwise, use the default value or an empty string */
  const parameters = transformation?.parameters
    .map((p) => {
      if (p.name === 'locale' && p.values.includes(selectedCountry)) {
        return { [p.name]: selectedCountry || p.default };
      }
      if (p.style === inputTypes.MULTISELECT) {
        return { [p.name]: p.default?.split(',') || [''] };
      }
      if (p.type === inputTypes.DATE) {
        return { [p.name]: p.default || undefined };
      }
      return { [p.name]: p.default || '' };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  /* Combine selectedTransformation, initialValues, presets, and parameters into the final object */
  return {
    exchangeStandard: selectedTransformation,
    ...initialValues,
    ...presets,
    ...parameters,
  };
};

export const generateCatalogue = (formValues, transformation) => {
  const catalogue = {};

  // Parse valid articles and join them with newline characters
  const validArticles = parseArticleKeys(formValues.articleList).uniqueIDs.join('\n');

  // Assign basic form values to catalogue
  catalogue.name = formValues.name;
  catalogue.description = formValues.description;
  catalogue.locale = formValues.locale;
  catalogue.exchangeStandard = formValues.exchangeStandard;

  // Map presets to the expected format
  catalogue.presets = transformation.presets.map((preset) => {
    const value = Array.isArray(formValues[preset.name])
      ? formValues[preset.name].filter(Boolean).join(',')
      : formValues[preset.name] || '';

    return preset.style === inputTypes.MULTISELECT
      ? { name: preset.name, value, style: inputTypes.MULTISELECT }
      : { name: preset.name, value };
  });

  // Map parameters to the expected format
  catalogue.parameters = transformation.parameters.map((param) => {
    let value = formValues[param.name] || '';

    // Handle special cases for specific parameters
    if (param.name === 'articleList' && validArticles) {
      value = validArticles;
      return { name: param.name, value, style: inputTypes.MULTILINE };
    }
    if (param.name === 'assortment' && validArticles) {
      value = 'custom';
      return { name: param.name, value };
    }
    if (param.style === inputTypes.MULTISELECT) {
      value = Array.isArray(formValues[param.name])
        ? formValues[param.name].filter(Boolean).join(',')
        : formValues[param.name] || '';
      return { name: param.name, value, style: inputTypes.MULTILINE };
    }

    return { name: param.name, value };
  });

  return catalogue;
};

const formatValue = (p) => {
  let val = p.value;
  if (p.name === 'cadFormats' || p.name === 'imageFormats' || p.name === 'preferredImageTypesIncluded') {
    val = p.value?.split(',') || p.default?.split(',');
  }
  return { [p.name]: val || (p.default || '') };
};

export const generateInitialDuplicationValues = (catalogueData, t) => ({
  name: `${catalogueData.name} - ${t('copy')}`,
  description: catalogueData.description,
  locale: catalogueData.locale,
  exchangeStandard: catalogueData.exchangeStandard,
  ...catalogueData.presets.map(formatValue).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  ...catalogueData.parameters.map(formatValue).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
});

export const generateDuplicatedCatalogue = (formValues, catalogueData) => {
  const catalogue = {
    name: formValues.name,
    description: formValues.description,
    locale: formValues.locale,
    exchangeStandard: formValues.exchangeStandard,
    presets: [],
    parameters: [],
  };

  const validArticles = parseArticleKeys(formValues.articleList).uniqueIDs;

  catalogue.presets = catalogueData.presets.map((p) => ({
    name: p.name,
    value: Array.isArray(formValues[p.name])
      ? formValues[p.name].filter(Boolean).join(',')
      : formValues[p.name] || '',
  }));

  catalogue.parameters = catalogueData.parameters.map((p) => {
    let value = '';

    if (p.name === 'articleList') {
      if (formValues.assortment === 'custom' && validArticles.length > 0) {
        value = validArticles.join('\n');
      }
    } else if (Array.isArray(formValues[p.name])) {
      value = formValues[p.name].filter(Boolean).join(',');
    } else {
      value = formValues[p.name] || '';
    }

    return {
      name: p.name,
      value,
    };
  });

  return catalogue;
};
