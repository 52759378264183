import {
  useState,
  useEffect,
} from 'react';
import {
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import Input from '../../Form/Input';
import ArticleList from './ArticleList';

function AssortmentOptions({
  assortmentParam, articleListParam,
  assortmentParamLabel = '', articleListParamLabel = '', dropdownSize = 3,
}) {
  const [assortment, , { setValue: setAssortmentValue }] = useField('assortment', 'text');
  const [assortmentType, setAssortmentType] = useState('custom');
  const { t, i18n } = useTranslation();

  const [exchangeStandard] = useField('exchangeStandard', 'text');

  useEffect(() => {
    let initialAssortmentType = 'step';
    if (assortment.value === 'custom') {
      initialAssortmentType = 'custom';
    }
    setAssortmentType(initialAssortmentType);
  }, [assortment.value, assortmentParam.values]);

  const handleAssortmentTypeChange = (value) => {
    setAssortmentType(value);
    setAssortmentValue(value === 'custom' ? 'custom' : assortmentParam.values[0]);
  };

  return (
    <>
      {(!assortmentParam && !articleListParam) && (
        <GridItem />
      )}
      {(!assortmentParam && articleListParam) && (
        <ArticleList
          exchangeStandard={exchangeStandard}
          articleListParam={articleListParam}
        />
      )}
      {(assortmentParam && articleListParam) && (
        <Grid gridGap="2">
          <GridItem>
            <RadioGroup onChange={handleAssortmentTypeChange} value={assortmentType}>
              <Stack direction="row">
                <Radio value="step">
                  {t('useStepAssortment')}
                </Radio>
                <Radio value="custom">
                  {t('defineCustomAssortment')}
                </Radio>
              </Stack>
            </RadioGroup>
          </GridItem>
          <GridItem>
            <Grid
              gridTemplateColumns={`repeat(${dropdownSize}, auto)`}
            >
              {assortmentType === 'step'
                && (
                  <GridItem>
                    <Input
                      type="parameter"
                      data={assortmentParam}
                      label={assortmentParamLabel}
                      transformation={exchangeStandard.value}
                      hint={i18n.exists(`parameters.${assortmentParam.name}.hint`) ? t(`parameters.${assortmentParam.name}.hint`) : ''}
                    />
                  </GridItem>
                )}
              {assortmentType === 'custom' && (
                <ArticleList
                  exchangeStandard={exchangeStandard}
                  articleListParam={articleListParam}
                  articleListParamLabel={articleListParamLabel}
                />
              )}
            </Grid>
          </GridItem>
        </Grid>
      )}
    </>
  );
}

AssortmentOptions.propTypes = {
  assortmentParam: PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  articleListParam: PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  assortmentParamLabel: PropTypes.string,
  articleListParamLabel: PropTypes.string,
  dropdownSize: PropTypes.number,
};

export default AssortmentOptions;
