import {
  Box,
  Button,
  Flex,
  VStack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';

function SuccessMessage(props) {
  const { showSuccessPage } = props;
  const { t } = useTranslation();

  return (
    <Box data-testid={getTestID('DexAssetsSuccessMessage')}>
      <VStack spacing="8" align="flex-start">
        <Text>
          {t('dexAssetsPortal.confirmationMessage')}
        </Text>
        <Text>
          {t('dexAssetsPortal.userSettingsMessage')}
        </Text>
        <Text>
          {t('dexAssetsPortal.newGenerationMessage')}
        </Text>
      </VStack>
      <Flex
        justifyContent="flex-end"
        gap="8"
        marginTop="8"
      >
        <Button
          type="submit"
          variant="primary"
          onClick={() => showSuccessPage(false)}
        >
          { t('dexAssetsPortal.createNew')}
        </Button>
      </Flex>
    </Box>
  );
}

SuccessMessage.propTypes = {
  showSuccessPage: PropTypes.func.isRequired,
};

export default SuccessMessage;
