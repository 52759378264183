import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';

const typeColourFilter = (type) => {
  const types = {
    approved: 'success',
    published: 'success',
    waitingForApproval: 'warning',
    jobCompleted: 'warning',
    jobPending: 'warning',
    requested: 'gray.400',
    scheduled: 'gray.400',
    jobStarted: 'geberit.500',
    declined: 'error',
    jobFailed: 'error',
  };

  return types[type] || null;
};

const MotionBox = motion(Box);

function Status({
  type, width = null, margin = null,
}) {
  const { t } = useTranslation();
  const colour = typeColourFilter(type);

  return (
    <Flex
      data-testid={getTestID('Status')}
      marginBottom="15px"
      alignItems="baseline"
      width={width}
      margin={margin}
    >
      {type === 'jobStarted' ? (
        <AnimatePresence>
          <MotionBox
            animate={{
              opacity: [1, 0.1, 1],
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1,
              repeat: Infinity,
            }}
            padding="4px"
            display="inline"
            marginRight="3px"
            width="9px"
            height="9px"
            borderRadius="100%"
            background={colour || 'white'}
            border={!colour && '1px solid'}
            borderColor={!colour && 'black'}
          />
        </AnimatePresence>
      ) : (
        <Box
          display="inline-block"
          marginRight="3px"
          width="9px"
          height="9px"
          borderRadius="50%"
          backgroundColor={colour || 'white'}
          border={!colour ? '1px solid' : 'none'}
          borderColor={!colour ? 'black' : 'transparent'}
          flexShrink={0}
        />
      )}
      <Text
        data-testid={getTestID('statusOfRevision')}
        margin={0}
        fontSize={12}
      >
        {t(`fileStatuses.${type}`)}
      </Text>
    </Flex>
  );
}

Status.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.string,
  margin: PropTypes.string,
};

export default Status;
