import {
  Box,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { getTestID } from '../../utils/utils';
import image from '../../assets/img/img-einblicke-acanto.jpg';
import logo from '../../assets/img/geberit-logo.png';
import BackgroundImage from '../ui/BackgroundImage';
import Error from '../ui/Error';
import LanguageSwitch from '../ui/LanguageSwitch';
import SignInButton from './SignInButton';

function Welcome({ error = '' }) {
  const { redirectError } = useLocation();
  const { t } = useTranslation();

  return (
    <Grid
      data-testid={getTestID('Welcome')}
      templateColumns="repeat(12, 1fr)"
      minHeight="100vh"
    >
      <GridItem
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        colSpan={3}
      >
        <Image
          data-testid={getTestID('Logo')}
          src={logo}
          alt="Geberit"
          maxWidth="182px"
          margin="56px"
        />
        <Box alignSelf="flex-end" margin="56px 56px 100px 56px">
          {error && <Error error={error} />}
          {redirectError && <Error error={redirectError} />}
          <Text>{t('onlyAvailable')}</Text>
          <Box margin="40px 0 80px 0">
            <SignInButton />
          </Box>
          <Text>
            {t('havingProblems')}
            &nbsp;
            <Link href="/">{t('contactSupport')}</Link>
          </Text>
          <LanguageSwitch />
        </Box>
      </GridItem>
      <GridItem colSpan={9} display="flex" position="relative">
        <BackgroundImage src={image} />
        <Heading
          data-testid={getTestID('welcomeText')}
          alignSelf="flex-end"
          margin="0 20px 107px 56px"
          fontSize="46px"
          lineHeight="normal"
          textTransform="uppercase"
        >
          {t('dex')}
          <Text
            display="block"
            marginTop="5"
            fontWeight="normal"
            fontSize="46px"
          >
            {t('welcome')}
          </Text>
        </Heading>
      </GridItem>
    </Grid>
  );
}

Welcome.propTypes = {
  error: PropTypes.string,
};

export default Welcome;
