import {
  Box,
  Flex,
  Button,
  IconButton,
} from '@chakra-ui/react';

import GlobeIcon from '../../icons/GlobeIcon';
import { getTestID } from '../../utils/utils';
import i18n from '../../i18n';

function LanguageSwitch() {
  return (
    <Flex
      data-testid={getTestID('LanguageSwitch')}
      position="relative"
      width="40px"
    >
      <IconButton
        variant="flat"
        padding={0}
        marginBottom="15px"
        icon={<GlobeIcon />}
      />
      <Box
        position="absolute"
        display="none"
        top="-110px"
        height="110px"
        _groupHover={{
          display: 'block',
        }}
      >
        <Button
          variant="filled"
          marginBottom="15px"
          padding={0}
          fontSize="12px"
          onClick={() => i18n.changeLanguage('de-DE')}
        >
          DE
        </Button>
        <Button
          variant="filled"
          padding={0}
          fontSize="12px"
          onClick={() => i18n.changeLanguage('en-GB')}
        >
          EN
        </Button>
      </Box>
    </Flex>
  );
}

export default LanguageSwitch;
