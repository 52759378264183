const breakpoints = {
  xs: '512px',
  sm: '768px',
  md: '1024px',
  lg: '1279px',
  xl: '1440px',
  '2xl': '1800px',
};

export default breakpoints;
