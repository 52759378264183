import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationDE from './translation/de/translation.json';
import translationEN from './translation/en/translation.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en-GB',
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      'en-GB': {
        translation: translationEN,
      },
      'de-DE': {
        translation: translationDE,
      },
    },
  });

export default i18n;
