import i18n from '../i18n';

export const getLocale = (value) => (i18n.exists(`locales.${value}`) && i18n.t(`locales.${value}`));

export const getCountry = (value) => (i18n.exists(`countries.${value}`) && i18n.t(`countries.${value}`));

export const getLanguage = (value) => (i18n.exists(`languages.${value}`) && i18n.t(`languages.${value}`));

export const isLocale = (value) => (i18n.exists(`locales.${value}`));
export const getLocaleName = (value) => (i18n.t(`locales.${value}`));

export function sortLocalesAlphabetically(a, b) {
  const optionA = getLocaleName(a);
  const optionB = getLocaleName(b);
  if (optionA < optionB) {
    return -1;
  }
  if (optionA > optionB) {
    return 1;
  }
  return 0;
}

export function sortTranslationsAlphabetically(a, b) {
  const optionA = i18n.t(a);
  const optionB = i18n.t(b);
  if (optionA < optionB) {
    return -1;
  }
  if (optionA > optionB) {
    return 1;
  }
  return 0;
}

export function sortAlphabetically(a, b) {
  if (isLocale(a) && isLocale(b)) {
    return (() => sortLocalesAlphabetically(a, b))();
  }
  return (() => sortTranslationsAlphabetically(a, b))();
}

export function formatSyncData(data) {
  const res = data.reduce((acc, item) => {
    // Find the index of the locale in the accumulator array
    let localeIndex = acc.findIndex((localeItem) => localeItem.locale === item.locale);

    // If the item locale does not exist as a property in the array, then create it
    if (localeIndex === -1) {
      acc.push({
        locale: item.locale,
      });

      // Retrieve the latest last inserted index as the current locale index
      localeIndex = acc.length - 1;
    }

    // Update the appropriate fields based on the syncType
    if (item.syncType === 'step') {
      acc[localeIndex].lastSyncToCtp = item.syncDate;
    }

    if (item.syncType === 'partsFile') {
      acc[localeIndex].lastSyncToFileExport = item.syncDate;
      acc[localeIndex].lastUpdated = item.lastUpdatedOn;
      acc[localeIndex].uuid = item.uuid;
    }

    return acc;
  }, []);

  return res;
}

export const isMatch = (r, path) => (
  r.filter((route) => new RegExp(route).test(path)).length > 0
);

export const formatDate = (input) => {
  if (!input) {
    return '';
  }
  const date = new Date(input);
  // Check for valid date
  // If x is a Date, isNaN(x) is equivalent to Number.isNaN(x.valueOf())
  if (date instanceof Date && !Number.isNaN(date)) {
    const dateString = date.toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const changeDateDelimiter = dateString.replace(/\//g, '.');
    return changeDateDelimiter;
  }
  return '';
};

export const formatDateTime = (input) => {
  if (!input) {
    return '';
  }
  const date = new Date(input);
  // Check for valid date
  // If x is a Date, isNaN(x) is equivalent to Number.isNaN(x.valueOf())
  if (date instanceof Date && !Number.isNaN(date)) {
    const dateString = date.toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      hourCycle: 'h23',
    });

    const changeDateDelimiter = dateString.replace(/\//g, '.');
    const removeDateTimeDelimiter = changeDateDelimiter.replace(',', '');
    return removeDateTimeDelimiter;
  }
  return '';
};

export const isDateThenFormat = (data) => (
  data.type === 'date' ? formatDate(data.value) : data.value
);

export const clearLocalStorageExcept = (excludedKeys) => {
  const keys = Object.keys(localStorage);

  keys.forEach((key) => {
    if (!excludedKeys.includes(key)) {
      localStorage.removeItem(key);
    }
  });

  // Verify that the non-excluded keys have been removed
  keys.forEach((key) => {
    if (!excludedKeys.includes(key) && localStorage.getItem(key) !== null) {
      console.error('Failed to remove key from localStorage');
    } else if (!excludedKeys.includes(key)) {
      console.log('Key removed from localStorage');
    }
  });
};

export const getTestID = (id) => {
  const isNotProduction = process.env.REACT_APP_MSAL_RETURN_HOST?.includes('stg')
    || process.env.REACT_APP_MSAL_RETURN_HOST?.includes('dev')
    || process.env.REACT_APP_MSAL_RETURN_HOST?.includes('localhost');
  return isNotProduction ? id : undefined;
};

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const isArrayWithContent = (arr) => Array.isArray(arr) && arr.length > 0;

export const formatFilters = (filters) => {
  const formatArray = (arr) => (Array.isArray(arr) && arr.length > 0 ? arr.join(',') : undefined);
  return {
    standards: formatArray(filters.standards),
    locales: formatArray(filters.locales),
    statuses: formatArray(filters.statuses),
  };
};

export const parseNumberFromString = (input) => {
  const trimmedInput = input.trim();
  const floatRegex = /^[+-]?\d+(\.\d+)?([eE][+-]?\d+)?$/;
  const intRegex = /^[+-]?\d+$/;

  if (intRegex.test(trimmedInput)) {
    return parseInt(trimmedInput, 10);
  }
  if (floatRegex.test(trimmedInput)) {
    return parseFloat(trimmedInput);
  }

  return NaN;
};

export const formatParametersPresetsData = (data, noValueLabel) => {
  const val = data.value;
  if (!val) {
    return noValueLabel;
  }
  if (isLocale(val)) {
    return getLocaleName(val);
  }
  if (data.valueType === 'date') {
    return formatDate(val);
  }
  const valArr = val.split(',');
  if (valArr.length > 1) {
    const newLine = valArr.join('\n');
    return newLine;
  }
  return i18n.t(val) || val;
};

/* Generates a decimal number with a specified number of zeros
  after the decimal point, followed by a 1. */
export const generateDecimal = (numZeros) => {
  // Validate input to ensure it's a non-negative integer
  if (!Number.isInteger(numZeros) || numZeros < 0) {
    return 1;
  }

  // Use exponentiation operator to create the desired number
  return 10 ** -numZeros;
};
