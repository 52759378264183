import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getTestID } from '../../utils/utils';
import ModalRemoveCollaborator from './ModalRemoveCollaborator';
import UserRole from '../ui/UserRole';

function CollaboratorsTable({ collaborators, userUuid, catalogue }) {
  const { t } = useTranslation();
  const [showRemove, setShowRemove] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState();

  return (
    <Box data-testid={getTestID('CollaboratorsAssigned')}>
      {collaborators?.length > 0 && (
        <Table variant="default" size="sm">
          <Thead>
            <Tr>
              <Th>{t('collaborator.name')}</Th>
              <Th width="20%" textAlign="center">{t('role')}</Th>
              {userUuid === catalogue.requestedBy.uuid && (
                <Th width="20%">&nbsp;</Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {collaborators.map((collaborator) => (
              <Tr key={collaborator.uuid}>
                <Td>
                  <Text fontWeight="700">{collaborator?.user?.fullName}</Text>
                  <Text>{collaborator.user.email}</Text>
                </Td>
                <Td textAlign="center">
                  <UserRole role={collaborator.user.role} />
                </Td>
                {userUuid === catalogue.requestedBy.uuid && (
                  <Td textAlign="right">
                    <Button
                      variant="link"
                      size="sm"
                      color="red.500"
                      onClick={() => {
                        setSelectedCollaborator(collaborator);
                        setShowRemove(true);
                      }}
                    >
                      {t('remove')}
                    </Button>
                    {showRemove && (
                      <ModalRemoveCollaborator
                        collaborator={selectedCollaborator}
                        open={showRemove}
                        setOpen={setShowRemove}
                        catalogueUuid={catalogue.catalogueUuid}
                      />
                    )}
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

CollaboratorsTable.propTypes = {
  collaborators: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      user: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  userUuid: PropTypes.string.isRequired,
  catalogue: PropTypes.shape({
    catalogueUuid: PropTypes.string.isRequired,
    requestedBy: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CollaboratorsTable;
