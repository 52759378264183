import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';

import {
  roles,
} from '../../../../utils/constants';
import {
  formatDateTime,
  sortLocalesAlphabetically,
} from '../../../../utils/utils';
import { useUserLocales } from '../../../../api/hooks';
import UserRole from '../../../ui/UserRole';
import ModalAddLocale from '../ModalAddLocale';
import ModalEditUserRole from '../ModalEditUserRole';
import ModalManageLocales from '../ModalManageLocales';
import ModalRemoveUser from '../ModalRemoveUser';

function UserRow(props) {
  const {
    user,
    type,
  } = props;
  const { t } = useTranslation();

  const [userRoleEdit, setUserRoleEdit] = useState(false);
  const [userDelete, setUserDelete] = useState(false);
  const [userLocale, setUserLocale] = useState(false);
  const [userLocaleAdd, setUserLocaleAdd] = useState(false);

  const { data: locales } = useUserLocales(user, type);

  const isActive = type === 'active' && user.role !== roles.DEFAULT;

  const editButtonLabelActiveUsers = user.role !== roles.DEFAULT ? t('changeRole') : t('assignRole');
  const editButtonLabel = type === 'active' ? editButtonLabelActiveUsers : t('user.approve');
  const showDeleteRoleModal = type === 'pending' || isActive;
  const deleteButtonLabel = type === 'active' ? t('user.revokeRole') : t('user.declineRequest');

  return (
    <>
      <Tr>
        <Td style={{ width: '300px' }}>
          <Flex flexDirection="column" alignItems="flex-start" style={{ width: '300px' }}>
            <Text fontWeight={700}>
              {user.fullName}
            </Text>
            <Text>
              {user.email}
            </Text>
          </Flex>
        </Td>
        <Td>
          {isActive
            ? <UserRole role={user.role} />
            : (
              <Text>
                {formatDateTime(user?.pendingTimestamp)}
              </Text>
            )}
        </Td>
        <Td>
          <Flex flexDirection="column" justifyContent="center">
            {user.role === roles.LDES && locales?.sort(
              (a, b) => sortLocalesAlphabetically(a.localeCode, b.localeCode),
            ).map((l) => (
              <Text key={l.localeCode}>
                {t(`locales.${l.localeCode}`)}
              </Text>
            ))}
          </Flex>
        </Td>
        <Td>
          <Flex justifyContent="right">
            {type === 'active' && user.role === roles.LDES && (
              <Button
                variant="link"
                onClick={() => setUserLocale(true)}
              >
                {t('locales.manage')}
              </Button>
            )}
            <Button
              variant="link"
              onClick={() => setUserRoleEdit(true)}
            >
              {editButtonLabel}
            </Button>
            {showDeleteRoleModal
              && (
                <Button
                  variant="link"
                  color="error"
                  onClick={() => setUserDelete(true)}
                >
                  {deleteButtonLabel}
                </Button>
              )}
          </Flex>
        </Td>
      </Tr>
      {userRoleEdit && (
        <ModalEditUserRole
          open={userRoleEdit}
          user={user}
          setOpen={setUserRoleEdit}
          type={type}
        />
      )}
      <ModalRemoveUser
        open={userDelete}
        user={user}
        setOpen={setUserDelete}
        type={type}
      />
      {userLocale && (
        <ModalManageLocales
          locales={locales}
          open={userLocale}
          user={user}
          setOpen={setUserLocale}
          setUserLocaleAdd={setUserLocaleAdd}
        />
      )}
      {userLocaleAdd && (
        <ModalAddLocale
          open={userLocaleAdd}
          user={user}
          setOpen={setUserLocaleAdd}
          setUserLocale={setUserLocale}
        />
      )}
    </>
  );
}

UserRow.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.string,
    fullName: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
    pendingTimestamp: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default UserRow;
